import { createAsyncThunk } from '@reduxjs/toolkit';

import initApiClient from 'services/ApiClient';

import fetchAllPages from 'utils/fetchAllPages';
import logSentryError from 'utils/sentry';

import { stringifyError, updateErrorMessage } from './helper';
import type { DetailedExport, Exports, ExportsError, PropsWithPubkey } from './types';

export const fetchExports = createAsyncThunk<Exports, void>(
  'exports/fetchExports',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const result = await fetchAllPages<Exports>('/v1/backoffice/exports', APIClient);
      return result;
    } catch (err) {
      logSentryError('[Export Hooks] fetch exports', err);
      throw err;
    }
  }
);

export const fetchDetailedExport = createAsyncThunk<DetailedExport, PropsWithPubkey['pubkey']>(
  'exports/fetchDetailedExport',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get(`/v1/backoffice/exports/${pubkey}`, null, {
        Accept: 'application/json',
      });
      const exportDetails = await response.json();
      return exportDetails;
    } catch (err) {
      logSentryError(`[useFetchDetailedExport ${pubkey} hook]`, err);
      throw err;
    }
  }
);

type DownloadExportArgs = {
  pubkey: string;
  name: string;
  onBeforeLoad: () => void;
};
export const downloadExport = createAsyncThunk<void, DownloadExportArgs>(
  'exports/download',
  async ({ pubkey, name, onBeforeLoad }, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      onBeforeLoad();
      const response = await APIClient.get(`/v1/backoffice/exports/${pubkey}.csv/`, null, {
        Accept: 'text/csv',
      });
      await response.download();
    } catch (err) {
      if (!(err instanceof Error)) return;

      const updatedError: ExportsError = updateErrorMessage(
        oldMessage => `Error while downloading "${name}": ${oldMessage}`
      )(err);

      logSentryError(
        () => `[Exports/DownloadExportButton] download csv export: ${stringifyError(updatedError)}`,
        updatedError
      );

      throw updatedError;
    }
  }
);
