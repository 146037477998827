export const recommendationMap = {
  preselect: 'Your custom recommendations',
  main: 'Your custom recommendations',
  upsale: 'Enhance your Routine',
  featured: 'New & Noteworthy',
  optional: 'Explore other custom products (see more)',
  inadvisable: 'Inadvisable (will also show up in the see more section)',
};

export const groupedByRecommendationSection = catalog => {
  const sections = ['preselect', 'main', 'upsale', 'featured', 'optional', 'inadvisable'];
  return sections.reduce((accumulator, section) => {
    accumulator[section] = catalog.filter(i => i.recommendation.section === section);
    return accumulator;
  }, {});
};
