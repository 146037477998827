import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import map from 'lodash/fp/map';
import range from 'lodash/fp/range';

import initApiClient from 'services/ApiClient';
import type { APIClient } from 'services/ApiClient.types';

import logSentryError from 'utils/sentry';

import type { Variants, VariantsState } from './types';

/* not ideal, we should not set this value arbitrarily
 see fetchAllPages function (requires 'link' response header with pages information) */
const ITEMS_PER_REQUEST = 50;

const createFetchVariantsPage =
  (APIClient: APIClient) =>
  (pageNumber = 1, itemsPerRequest = ITEMS_PER_REQUEST) =>
    APIClient.get(`/v1/backoffice/variants?page_size=${itemsPerRequest}&page=${pageNumber}`);

// Page starts at 1, default pagination is 50
export const fetchLanesVariants = createAsyncThunk<{ results: Variants }>(
  'variants/fetchVariantsPage',
  async (_, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const fetchVariantsPage = createFetchVariantsPage(APIClient);
      const query = await APIClient.get(
        `/v1/backoffice/variants/?page_size=${ITEMS_PER_REQUEST}&page=${1}`
      );
      const { count: totalCount, results: resultsPage1 } = await query.json();

      const totalPagesNeeded = Math.ceil(totalCount / ITEMS_PER_REQUEST);
      const pagesToFetch = range(2, totalPagesNeeded + 1); // [2, 3, ... , totalPages]

      const subsequentResponses = await Promise.all(map(fetchVariantsPage, pagesToFetch));
      const results = await subsequentResponses.reduce(async (accPromise, currPromise) => {
        const payload = await currPromise.json();
        const acc = await accPromise; // accumulator is a Promise during the async iteration
        return acc.concat(payload.results);
      }, resultsPage1);

      return { results };
    } catch (err) {
      logSentryError('[Variants Actions] fetch variants', err);
      throw err;
    }
  }
);

const initialState: VariantsState = {
  entries: [],
  status: 'idle',
  error: null,
};

const variantsSlice = createSlice({
  name: 'variants',
  initialState,
  reducers: {
    dispatchVariants(draftState, action: PayloadAction<Variants>) {
      draftState.entries = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLanesVariants.pending, draftState => {
        draftState.status = 'pending';
        draftState.entries = [];
        draftState.error = null;
      })
      .addCase(fetchLanesVariants.fulfilled, (draftState, action) => {
        const { results } = action.payload;

        draftState.status = 'fulfilled';
        draftState.entries = results;
      })
      .addCase(fetchLanesVariants.rejected, (draftState, action) => {
        draftState.status = 'rejected';
        draftState.error = action.error;
      });
  },
});

const { reducer, actions } = variantsSlice;

export const { dispatchVariants } = actions;

export default reducer;
