import assign from 'lodash/fp/assign';
import concat from 'lodash/fp/concat';
import eq from 'lodash/fp/eq';
import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import forEach from 'lodash/fp/forEach';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import startsWith from 'lodash/fp/startsWith';
import uniq from 'lodash/fp/uniq';
import uniqBy from 'lodash/fp/uniqBy';

import { humanize } from 'helpers';
import { createSelector } from 'reselect';

import { shouldShowAccessoryGwp } from 'dux/featureFlags/selectors';

export const getProductionState = state => state.production;
export const getSettingsState = state => state.settings;

export const getSelectedEndOfLaneScreen = createSelector(
  getSettingsState,
  ({ selectedAutomationScreen }) => selectedAutomationScreen
);

export const getSelectedLaneName = createSelector(
  getSettingsState,
  getProductionState,
  ({ selectedLane }, { lanes }) =>
    lanes.length !== 0
      ? lanes
          .filter(lane => (includes(lane.pubkey, selectedLane) ? lane.name : null))
          .map(lane => lane.name)[0]
      : ''
);

export const shouldFocusQrCodeField = createSelector(
  getProductionState,
  get('shouldFocusQrCodeField')
);

export const getPrepackLabs = createSelector(getProductionState, get('prepackLabs'));

// Production Lanes
export const getLanes = createSelector(getProductionState, get('lanes'));
export const getLanesForCategory = createSelector(getLanes, getSettingsState, (lanes, settings) => {
  const category = settings.category ?? 'haircare';
  return filter({ category }, lanes);
});

export const getAutomationLanePubkey = createSelector(
  getLanes,
  flow(find({ name: 'Automation Lab 1' }), get('pubkey'))
);

export const getSkincareAutomationLanePubkey = createSelector(
  getLanes,
  flow(find({ name: 'Skincare Automation' }), get('pubkey'))
);

// Production Details
export const getProductionDetails = createSelector(getProductionState, get('details'));
export const getScannedShippingLabel = createSelector(
  getProductionDetails,
  get('scannedShippingLabel')
);
export const getProductionAction = createSelector(getProductionDetails, get('action'));

export const getProductionLane = createSelector(getProductionDetails, get('lane'));

export const getScannedPubkeys = createSelector(getProductionDetails, get('scannedPubkeys'));

// Microhold

export const getQualityTestsBatches = createSelector(getProductionState, get('quality_tests'));

export const getActiveVariantsByLane = createSelector(getLanes, lanes => {
  const arrays = map(item => map('type', item.variants), lanes);
  return uniq(flatten(arrays));
});

// Production Details Box
export const getBoxDetail = createSelector(getProductionDetails, get('box'));

export const getShipping = createSelector(getBoxDetail, get('shipping'));

export const getShippingTrackingId = createSelector(getShipping, get('tracking_id'));

export const getShippingCarrier = createSelector(getShipping, get('carrier'));
export const getBoxHasCarrierDiscrepancy = createSelector(getShipping, get('carrier_discrepancy'));
export const getShippingCountry = createSelector(
  getBoxDetail,
  get('order.shipping_address.country')
);

export const isBoxShippingScannable = createSelector(getShipping, get('is_scannable'));
export const getBoxItems = createSelector(getBoxDetail, get('items'));

export const getPackingModules = createSelector(getBoxDetail, get('packing_modules'));
export const getBoxPubkey = createSelector(getBoxDetail, get('pubkey'));
export const getProdItems = createSelector(getBoxDetail, get('proditems'));

export const getNeedBuffer = createSelector(getBoxDetail, get('need_buffer'));
export const getBoxNeedBuffer = createSelector(getBoxDetail, get('need_buffer'));

export const getBoxOrder = createSelector(getBoxDetail, get('order'));

// Production Details Box Items
export const getOrderItemsFromBox = createSelector(getBoxDetail, get('items'));

const getLaneDetails = createSelector([getProductionLane, getLanes], (lane, lanes) =>
  find({ pubkey: lane }, lanes)
);
const getLaneDetailsVariants = createSelector(getLaneDetails, get('variants'));

export const shouldDisplayPumpOrCollateral = createSelector(
  getLaneDetailsVariants,
  lanesDetailsVariants => {
    // No lane set on redux in packingstation:
    if (!lanesDetailsVariants) return true;
    // Remove scrunchies, sticker and toiletry_bag as they aren't bulky but we still shouldn't display pump or collaterals
    const nonBulkyItems = filter(
      item =>
        item.slug !== 'satin_scrunchies_pair' &&
        !startsWith('sealing_sticker', item.slug) &&
        !startsWith('sticker', item.slug) &&
        item.slug !== 'toiletry_bag',
      lanesDetailsVariants
    );
    return !every('is_bulky', nonBulkyItems);
  }
);

export const getLaneByPubkey = createSelector(
  getLanes,
  (state, props) => props.lanePubkey,
  (lanes, pubkey) => find({ pubkey }, lanes)
);

export const getLaneName = createSelector(getLaneByPubkey, get('name'));

// Production Details Box Item By Pubkey
export const getOrderItemByPubkey = createSelector(
  getOrderItemsFromBox,
  (state, props) => props.itemPubkey,
  shouldShowAccessoryGwp,
  (orderItems, pubkey, showAccessoryGwp) => {
    const foundItem = find({ pubkey }, orderItems);
    if (!showAccessoryGwp || !foundItem?.variant?.is_bulky) return foundItem;
    const filteredItems = filter(
      i =>
        i.pubkey !== foundItem.pubkey &&
        i.variant.pubkey === foundItem.variant.pubkey &&
        i.variant.is_bulky,
      orderItems
    );
    let updatedOrderItem = foundItem;
    forEach(item => {
      updatedOrderItem = assign(foundItem, {
        quantity: foundItem.quantity + item.quantity,
        quantity_in_box: foundItem.quantity_in_box + item.quantity_in_box,
        production_items: concat(foundItem.production_items, item.production_items),
      });
    }, filteredItems);

    return updatedOrderItem;
  }
);

// Item Variant
export const getVariant = createSelector(getOrderItemByPubkey, get('variant'));
export const getVariantPubkey = createSelector(getVariant, get('pubkey'));
export const getVariantSlug = createSelector(getVariant, get('slug'));
export const getVariantVersion = createSelector(getVariant, get('version'));
export const isFormula = createSelector(getVariant, get('is_formula'));
export const isMixable = createSelector(getVariant, get('is_mixable'));
export const isProducible = createSelector(getVariant, get('is_producible'));
export const isBulky = createSelector(getVariant, get('is_bulky'));
export const isScannable = createSelector(getVariant, get('is_scannable'));
export const isGwp = createSelector(getVariant, get('is_gwp'));
export const isLoyaltyGift = createSelector(getVariant, get('is_loyalty_gift'));

// Item Variant Product
const getVariantProduct = createSelector(getVariant, get('product'));
export const getProductPubkey = createSelector(getVariantProduct, get('pubkey'));
export const getProductType = createSelector(getVariantProduct, get('type'));
export const isBrush = createSelector(getProductType, eq('brush'));
export const isPump = createSelector(getProductType, eq('pump'));
export const isPostcard = createSelector(getProductType, eq('postcard'));

export const getItemIcon = createSelector(
  [getVariantSlug, getProductType, isPump, isGwp, isLoyaltyGift],
  (variantSlug, productType, pump, gwp, loyalty) => {
    if (gwp || loyalty) return 'gwp';
    if (pump) return `${productType}__${variantSlug}`;
    if (productType === 'collateral') return humanize(variantSlug);
    if (startsWith('supplement', productType) && includes('pouch', variantSlug)) {
      return 'supplement_refills';
    }
    return productType;
  }
);

const findFirstNotScannedProdItem =
  scannedPubkeys =>
  ([currentProdItem, ...restProdItems] = []) => {
    if (!currentProdItem) {
      // We exhausted all possible pubkeys, returning null
      return null;
    }
    if (includes(currentProdItem.pubkey, scannedPubkeys)) {
      // Already Scanned - Retrying with the rest of the proditems
      return findFirstNotScannedProdItem(scannedPubkeys)(restProdItems);
    }
    // Found the pubkey to scan
    return currentProdItem;
  };

const getProdItem = createSelector(getOrderItemByPubkey, get('production_items'));
export const getProdItemPubkey = createSelector(
  [getProdItem, getScannedPubkeys],
  (prodItems, scannedPubkeys) =>
    flow(findFirstNotScannedProdItem(scannedPubkeys), get('pubkey'))(prodItems)
);

const getItemObject = createSelector(getOrderItemByPubkey, get('item_object'));
export const getSerialNo = createSelector(getItemObject, get('serial_no'));
export const getItemObjectPubkey = createSelector(getItemObject, get('pubkey'));
const getItemObjectCustomization = createSelector(getItemObject, get('customization'));
export const getCustomizationMonograms = createSelector(
  getItemObjectCustomization,
  get('monograms')
);

const getSkeleton = createSelector(getItemObject, get('skeleton'));
const getSkeletonName = createSelector(getSkeleton, get('name'));
const getSkeletonType = createSelector(getSkeleton, get('type'));
const getSkeletonSlug = createSelector(getSkeleton, get('slug'));

export const getProductionLabel = createSelector(
  getVariantSlug,
  getVariantVersion,
  getProductType,
  getSkeletonName,
  getSkeletonType,
  getSkeletonSlug,
  isPump,
  isGwp,
  (variantSlug, variantVersion, productType, name, type, slug, isVariantPump, isVariantGwp) => {
    if (!name || startsWith('supplement', type)) {
      return `${humanize(variantSlug)} ${
        productType === 'postcard' && includes('membership', variantSlug)
          ? humanize(`${variantSlug}_${variantVersion}`)
          : ''
      } ${
        isVariantPump || (isVariantGwp && !includes('gwp', variantSlug))
          ? humanize(productType)
          : ''
      }`;
    }

    if (type === 'serum') {
      return name;
    }

    if (slug === 'dry_shampoo_normal_big') {
      return `DS ${name}`;
    }
    if (includes(type, ['leavein', 'shimmer_hairoil'])) {
      return `${humanize(type)}`; // XXX Would break if moving to any other version than V1!
    }

    return `${humanize(type)} ${name}`;
  }
);

export const getProductionLaneName = createSelector(
  getProductionState,
  ({ lanes }) =>
    lanePubkey =>
      find({ pubkey: lanePubkey }, lanes)?.name
);

export const getSkeletonIsProducibleOnLane = createSelector(
  getProductionState,
  ({ lanes }) =>
    (item, lanePubkey) => {
      const lane = flow(find({ pubkey: lanePubkey }), get('pubkey'))(lanes);
      return lane === get('production_lane', item);
    }
);

export const getProductionError = createSelector(getProductionState, ({ error }) => error);
export const isLoading = createSelector(getProductionState, ({ loading }) => loading);

export const getDisplayedNameLane = createSelector(
  getProductionLaneName,
  getProdItems,
  (productionLaneName, prodItems) =>
    flow(
      uniqBy('production_lane'),
      map(item => productionLaneName(item.production_lane))
    )(prodItems)
);

export const productionError = createSelector(getProductionState, ({ error }) => error);
export const productionSubErrorMessages = createSelector(
  getProductionState,
  ({ subErrorMessages }) => subErrorMessages
);

export const getSuccessMessage = createSelector(getProductionState, ({ success }) => success);

export const getReturnButtonNavigatePath = createSelector(
  [getProductionAction, getNeedBuffer],
  (action, needBuffer) => {
    if (action === 'packing') {
      if (needBuffer) return '/production';
      return '/production/packing';
    }
    if (action === 'buffering') return '/production/buffer-spaces/partial';
    if (action === 'buffered') return '/production/buffer-spaces/completed';

    // for any other case
    return null;
  }
);
