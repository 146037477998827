import getAbsoluteURL from 'utils/getAbsoluteURL';

import defaultHeaders from './DefaultHeaders';

export const simpleRequest =
  method =>
  (route, params, headerOverrides = {}) =>
    fetch(getAbsoluteURL(route), {
      method,
      headers: {
        ...defaultHeaders,
        ...headerOverrides,
        // No automatic auth header stuffing
      },
      ...(params ? { body: JSON.stringify(params) } : {}), // body is set only if it has params
    }); // Return the response as-is

// No fancy URL manipulation, this service is designed to be as simple as possible
export const get = simpleRequest('GET');
export const patch = simpleRequest('PATCH');
export const put = simpleRequest('PUT');
export const post = simpleRequest('POST');
