import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';

import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

import type { BufferSpace, Stats } from './types/bufferSpaces-list-types';

type BufferSpacesState = RootState['bufferSpaces'];
type SettingsState = RootState['settings'];

const getBufferSpaces: OmsSelector<BufferSpacesState> = state => state.bufferSpaces;
const getSettings: OmsSelector<SettingsState> = state => state.settings;

export const getBufferSpacesList: OmsSelector<BufferSpacesState['bufferSpaces']> = createSelector(
  getBufferSpaces,
  get('bufferSpaces')
);
export const getBufferBins: OmsSelector<BufferSpacesState['bufferBins']> = createSelector(
  getBufferSpaces,
  get('bufferBins')
);

export const getStatus: OmsSelector<BufferSpacesState['status']> = createSelector(
  getBufferSpaces,
  get('status')
);

export const getError: OmsSelector<BufferSpacesState['error']> = createSelector(
  getBufferSpaces,
  get('error')
);

export const getPreviousCallParams: OmsSelector<BufferSpacesState['previousCallParams']> =
  createSelector(getBufferSpaces, get('previousCallParams'));

export const getDefectBufferSpaceStats: OmsSelector<Stats> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Defect Buffer Space' }), get('stats'))
);

export const getDefectBufferSpacePubkey: OmsSelector<BufferSpace['pubkey']> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Defect Buffer Space' }), get('pubkey'))
);

export const getDefaultBufferSpacePubkey: OmsSelector<BufferSpace['pubkey']> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Canada Buffer Space' }), get('pubkey'))
);
export const getAutomationBufferSpaceStats: OmsSelector<Stats> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Automation Buffer Space' }), get('stats'))
);

export const getAutomationBufferSpacePubkey: OmsSelector<BufferSpace['pubkey']> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Automation Buffer Space' }), get('pubkey'))
);

export const getSkincareBufferSpacePubkey: OmsSelector<BufferSpace['pubkey']> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Skincare Buffer Space' }), get('pubkey'))
);

export const getCanadaBufferSpacePubkey: OmsSelector<BufferSpace['pubkey']> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Canada Buffer Space' }), get('pubkey'))
);

export const getCanadaBufferSpaceStats: OmsSelector<Stats> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Canada Buffer Space' }), get('stats'))
);

export const getUnusedBufferSpacePubkey: OmsSelector<BufferSpace['pubkey']> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Unused Bins Buffer Space' }), get('pubkey'))
);

export const getUnusedBufferSpaceStats: OmsSelector<Stats> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Unused Bins Buffer Space' }), get('stats'))
);

export const getSkincareBufferSpaceStats: OmsSelector<Stats> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Skincare Buffer Space' }), get('stats'))
);

export const getSkincareDefectBufferSpacePubkey: OmsSelector<BufferSpace['pubkey']> =
  createSelector(
    getBufferSpacesList,
    flow(find({ name: 'Skincare Defect Buffer Space' }), get('pubkey'))
  );

export const getSkincareDefectBufferSpaceStats: OmsSelector<Stats> = createSelector(
  getBufferSpacesList,
  flow(find({ name: 'Skincare Defect Buffer Space' }), get('stats'))
);

export const getSelectedBufferSpaceName: OmsSelector<BufferSpace['name']> = createSelector(
  getSettings,
  getBufferSpacesList,
  ({ selectedBufferSpace }, bufferSpaces) =>
    get('name', find({ pubkey: selectedBufferSpace }, bufferSpaces)) ?? 'Canada Buffer Space'
);

export const getSelectedBufferSpaceNextStatuses: OmsSelector<BufferSpace['next_statuses']> =
  createSelector(getSettings, getBufferSpacesList, ({ selectedBufferSpace }, bufferSpaces) =>
    flow(find({ pubkey: selectedBufferSpace }), get('next_statuses'))(bufferSpaces)
  );

export const getSelectedBufferSpaceSortingColumn: OmsSelector<BufferSpace['sorting_column']> =
  createSelector(getSettings, getBufferSpacesList, ({ selectedBufferSpace }, bufferSpaces) =>
    flow(find({ pubkey: selectedBufferSpace }), get('sorting_column'))(bufferSpaces)
  );

export const getSelectedBufferSpaceSortingOrder: OmsSelector<BufferSpace['sorting_order']> =
  createSelector(getSettings, getBufferSpacesList, ({ selectedBufferSpace }, bufferSpaces) =>
    flow(find({ pubkey: selectedBufferSpace }), get('sorting_order'))(bufferSpaces)
  );
