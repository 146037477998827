import type { SettingsState } from 'dux/settings/types';

import * as ViewMode from './viewModes';

export type SettingsContentDataOption = { value: string; label: string };

export type SettingsContentData = {
  title: string;
  description: string;
  options: Array<SettingsContentDataOption>;
};

export type SettingsContent = {
  category: SettingsContentData;
  viewMode: SettingsContentData;
  bufferScanner: SettingsContentData;
  automationScreens: SettingsContentData;
  prepackLabs: Pick<SettingsContentData, 'title' | 'description'>;
};

const settings: SettingsContent = {
  category: {
    title: 'Category',
    description: 'Category',
    options: [
      {
        value: 'haircare',
        label: 'Haircare',
      },
      {
        value: 'skincare',
        label: 'Skincare',
      },
    ],
  },
  viewMode: {
    title: 'View Mode',
    description: 'View Mode',
    options: [
      {
        value: ViewMode.ADMIN,
        label: 'Admin',
      },
      {
        value: ViewMode.LAB,
        label: 'Old Flow - Production/Packing Management',
      },
      {
        value: ViewMode.PRODUCTION_LAB_AUTOMATION,
        label: 'New Flow - Manual Production lab',
      },
      {
        value: ViewMode.AUTOMATION_SCREENS,
        label: 'New Flow - Automation Conveyors A/B/C Management',
      },
      {
        value: ViewMode.QUALITY_HOLD,
        label: 'Quality Hold',
      },
      {
        value: ViewMode.PALLETS_MANAGEMENT,
        label: 'Pallets Management',
      },
      {
        value: ViewMode.PALLETIZE_BOXES,
        label: 'Palletize Boxes',
      },
      {
        value: ViewMode.LABELING,
        label: 'Labeling Defect',
      },
      {
        value: ViewMode.EOL_BUFFER_SPACE,
        label: 'New Flow - Buffer Trolleys Management',
      },
      {
        value: ViewMode.BUFFER_SPACE,
        label: 'New Flow - Buffer Space Management',
      },
      {
        value: ViewMode.PREPACK,
        label: 'New Flow - Prepacking management',
      },
      {
        value: ViewMode.PACKING_STATION,
        label: 'New Flow - Packing management',
      },
    ],
  },
  bufferScanner: {
    title: 'Buffer Scanner',
    description: 'Scanner Settings',
    options: [
      {
        value: 'off',
        label: 'No scanning required',
      },
      {
        value: 'partialAndShippingLabelMandatory',
        label: 'Scanning option, mandatory scan of shipping label',
      },
      {
        value: 'partial',
        label: 'Scanning required (with button backup), no mandatory scan of shipping label',
      },
      {
        value: 'full',
        label: 'Scanning required, mandatory scan of shipping label',
      },
    ],
  },
  automationScreens: {
    title: 'Automation Screens',
    description: 'Automation Screens',
    options: [
      {
        value: 'eola',
        label: 'Conveyor A - Complete Orderboxes',
      },
      {
        value: 'eolb',
        label: 'Conveyor B - Partial Orderboxes',
      },
      {
        value: 'eolbComplex',
        label: 'Conveyor B - Needs Complex',
      },
      {
        value: 'eolc',
        label: 'Conveyor C - Defects',
      },
    ],
  },
  prepackLabs: {
    title: 'Prepack station',
    description: 'Prepack station',
  },
};

const defaultSettings: Pick<
  SettingsState,
  'bufferScanner' | 'viewMode' | 'selectedAutomationScreen'
> = {
  bufferScanner: 'off',
  viewMode: 'admin',
  selectedAutomationScreen: 'eola',
};

export { settings, defaultSettings };
