import React, { type FC, type ReactNode } from 'react';

import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import slice from 'lodash/fp/slice';
import uniq from 'lodash/fp/uniq';

import { Divider } from '@material-ui/core';

type MenuItem = {
  name: string;
  dataTestId: string;
  to: string;
  icon: React.ReactNode;
  group?: string;
};

type GroupedMenu = Record<string, MenuItem[]>;

const constructMenu = (menu: MenuItem[], fillListItem: FC<MenuItem>): ReactNode => {
  if (isEmpty(menu)) return null;
  const preferredOrdering: string[] = flow(map('group'), uniq)(menu);
  const groupedMenu: GroupedMenu = groupBy('group', menu);

  const menuWithDividers: ReactNode = flow(
    map((groupName: string) => [
      ...groupedMenu[groupName],
      <Divider key={`${groupName} Divider`} />,
    ]),
    flatten,
    map((menuItem: MenuItem) => (menuItem.group ? fillListItem(menuItem) : menuItem)),
    slice(0, -1)
  )(preferredOrdering);

  return menuWithDividers;
};

export default constructMenu;
