import flow from 'lodash/fp/flow';
import isString from 'lodash/fp/isString';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import split from 'lodash/fp/split';
import upperFirst from 'lodash/fp/upperFirst';

import collateralsMap from './utils/collaterals';
import consultationMapping from './utils/consultation';
import powderComplexMap from './utils/powderComplex';
import { productsMap } from './utils/products';
import pumpsMap from './utils/pumps';
import { recommendationMap } from './utils/recommendation';
import subscriptionMap from './utils/subscription';
import surveyMap from './utils/survey';

export const humanize = s => {
  if (!isString(s)) return s;
  if (consultationMapping[s]) return consultationMapping[s];
  if (powderComplexMap[s]) return powderComplexMap[s];
  if (productsMap[s]) return productsMap[s];
  if (collateralsMap[s]) return collateralsMap[s];
  if (recommendationMap[s]) return recommendationMap[s];
  if (subscriptionMap[s]) return subscriptionMap[s];
  if (surveyMap[s]) return surveyMap[s];
  if (pumpsMap[s]) return pumpsMap[s];
  return s.replace(/_/g, ' ').replace(/(\w+)/g, match => match.charAt(0) + match.slice(1));
};

export const capitalize = inputString => {
  if (!inputString || !isString(inputString)) return inputString;

  return flow(split(' '), map(upperFirst), join(' '))(inputString);
};

export const pageTitle = title => {
  if (title) return `${title} | Prose Backoffice`;
  return 'Prose Backoffice';
};

const arrayHasSameElements = (arr1, arr2) =>
  arr1.every(elmt => arr2.indexOf(elmt) > -1) && arr2.every(elmt => arr1.indexOf(elmt) > -1);
export const valuesAreEqual = (a, b) => {
  if (typeof b === 'undefined') return false;
  if (Array.isArray(a) && Array.isArray(b)) return arrayHasSameElements(a, b);

  if (a === null && b === null) return true;

  // Unexpected to have deep objects comparison for now
  if (typeof a === 'object') return false;

  return a === b;
};

export const pluralize = (word, quantity) => {
  if (quantity > 1) return `${word}s`;

  return word;
};
