import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  background: {
    borderRadius: '20px',
    backgroundColor: '#000',
    opacity: 0.2,
    height: '100px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centered: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  loader: {
    margin: theme.spacing(2),
  },
});

const CenteredProgress = ({ classes }) => (
  <div className={classes.centered} data-testid="loader">
    <Fade in style={{ transitionDelay: '100ms' }} unmountOnExit>
      <div>
        <div className={classes.background}>
          <CircularProgress className={classes.loader} color="secondary" />
        </div>
      </div>
    </Fade>
  </div>
);

CenteredProgress.propTypes = forbidExtraProps({
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
});

export default withStyles(styles)(CenteredProgress);
