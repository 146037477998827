import {
  AUTO_DISPATCH,
  BUFFER_SPACES_LIST,
  CUSTOMER_LIST,
  CUSTOMER_VIEW,
  DATA_PORTAL,
  EXPORTS,
  FAST_CONSULTATION,
  FEEDBACK_LIST,
  GIFT_DETAIL,
  GIFT_LIST,
  HELP,
  HELP_BOX_OBJECT_VIEWER,
  HELP_REVIEW_AND_REFINE_VIEWER,
  HELP_STATUS_VIEWER,
  MANUAL_DISPATCH,
  NEW_FLOW_PRODUCTION_SCREEN,
  OLD_FLOW_PRODUCTION_SCREEN,
  ORDER_LIST,
  ORDER_VIEW,
  PACK_SCREEN,
  PRINT_COLLATERALS,
  PRODUCTION_LIST,
  SUBSCRIPTION_LIST,
  SUBSCRIPTION_VIEW,
  TEAMMATES_DETAIL,
  TEAMMATES_EDIT,
  TEAMMATES_LIST,
} from './teammatesPermissions';
import * as ROLES from './teammatesRoles';

const navigationPermissionsForGroup = [
  {
    slug: 'Admins',
    navigationPermissions: [
      EXPORTS,
      CUSTOMER_LIST,
      CUSTOMER_VIEW,

      ORDER_LIST,
      ORDER_VIEW,

      SUBSCRIPTION_LIST,
      SUBSCRIPTION_VIEW,

      FEEDBACK_LIST,

      GIFT_LIST,
      GIFT_DETAIL,

      PRODUCTION_LIST,
      OLD_FLOW_PRODUCTION_SCREEN,
      NEW_FLOW_PRODUCTION_SCREEN,
      PACK_SCREEN,

      BUFFER_SPACES_LIST,

      AUTO_DISPATCH,
      MANUAL_DISPATCH,
      PRINT_COLLATERALS,

      TEAMMATES_LIST,
      TEAMMATES_DETAIL,
      TEAMMATES_EDIT,

      EXPORTS,
      DATA_PORTAL,

      FAST_CONSULTATION,
      HELP,
      HELP_STATUS_VIEWER,
      HELP_BOX_OBJECT_VIEWER,
      HELP_REVIEW_AND_REFINE_VIEWER,
    ],
  },
  {
    slug: 'CX Supervisor',
    navigationPermissions: [
      CUSTOMER_LIST,
      CUSTOMER_VIEW,

      ORDER_LIST,
      ORDER_VIEW,

      SUBSCRIPTION_LIST,
      SUBSCRIPTION_VIEW,

      FEEDBACK_LIST,

      GIFT_LIST,
      GIFT_DETAIL,

      PRODUCTION_LIST,
      BUFFER_SPACES_LIST,

      EXPORTS,
      DATA_PORTAL,

      TEAMMATES_LIST,
      TEAMMATES_DETAIL,
      TEAMMATES_EDIT,

      FAST_CONSULTATION,
    ],
  },
  {
    slug: 'Innovation Test Conso',
    navigationPermissions: [CUSTOMER_LIST, CUSTOMER_VIEW, EXPORTS, DATA_PORTAL, FAST_CONSULTATION],
  },
  {
    slug: 'R&D Formulation',
    navigationPermissions: [CUSTOMER_LIST, CUSTOMER_VIEW, DATA_PORTAL, FAST_CONSULTATION],
  },
  {
    slug: 'R&D Scientific Communication',
    navigationPermissions: [DATA_PORTAL],
  },
  {
    slug: 'R&D Product Perf Eval',
    navigationPermissions: [CUSTOMER_LIST, CUSTOMER_VIEW, DATA_PORTAL],
  },
  {
    slug: 'Ops Supervisor',
    navigationPermissions: [
      CUSTOMER_LIST,
      CUSTOMER_VIEW,

      ORDER_LIST,
      ORDER_VIEW,

      SUBSCRIPTION_LIST,
      SUBSCRIPTION_VIEW,

      FEEDBACK_LIST,

      GIFT_LIST,
      GIFT_DETAIL,
      EXPORTS,
      DATA_PORTAL,

      TEAMMATES_LIST,
      TEAMMATES_DETAIL,
      TEAMMATES_EDIT,

      PRODUCTION_LIST,
      OLD_FLOW_PRODUCTION_SCREEN,
      NEW_FLOW_PRODUCTION_SCREEN,
      PACK_SCREEN,

      BUFFER_SPACES_LIST,

      AUTO_DISPATCH,
      MANUAL_DISPATCH,
      PRINT_COLLATERALS,
    ],
  },
  {
    slug: 'Ops Operator',
    navigationPermissions: [
      CUSTOMER_LIST,

      ORDER_LIST,
      ORDER_VIEW,

      PRODUCTION_LIST,
      OLD_FLOW_PRODUCTION_SCREEN,
      NEW_FLOW_PRODUCTION_SCREEN,
      PACK_SCREEN,
      PRINT_COLLATERALS,

      BUFFER_SPACES_LIST,
    ],
  },
  {
    slug: 'Ops Temp',
    navigationPermissions: [
      PRODUCTION_LIST,
      OLD_FLOW_PRODUCTION_SCREEN,
      NEW_FLOW_PRODUCTION_SCREEN,
      PACK_SCREEN,

      BUFFER_SPACES_LIST,
    ],
  },
  {
    slug: 'CX Associate',
    navigationPermissions: [
      CUSTOMER_LIST,
      CUSTOMER_VIEW,

      ORDER_LIST,
      ORDER_VIEW,

      SUBSCRIPTION_LIST,
      SUBSCRIPTION_VIEW,

      FEEDBACK_LIST,

      GIFT_LIST,
      GIFT_DETAIL,
    ],
  },
  {
    slug: 'CX Outsourced',
    navigationPermissions: [
      CUSTOMER_VIEW,
      CUSTOMER_LIST,
      ORDER_LIST,
      ORDER_VIEW,
      SUBSCRIPTION_LIST,
      SUBSCRIPTION_VIEW,
    ],
  },
  // ROLES
  {
    slug: ROLES.CX_GENERAL.name,
    navigationPermissions: ROLES.CX_GENERAL.permissions,
  },
  {
    slug: ROLES.CX_MANAGEMENT.name,
    navigationPermissions: ROLES.CX_MANAGEMENT.permissions,
  },
  {
    slug: ROLES.CX_OUTSOURCED.name,
    navigationPermissions: ROLES.CX_OUTSOURCED.permissions,
  },
  {
    slug: ROLES.DATA_ANALYSIS.name,
    navigationPermissions: ROLES.DATA_ANALYSIS.permissions,
  },
  {
    slug: ROLES.DATA_DEVELOPER.name,
    navigationPermissions: ROLES.DATA_DEVELOPER.permissions,
  },
  {
    slug: ROLES.INNOVATION_INTERN.name,
    navigationPermissions: ROLES.INNOVATION_INTERN.permissions,
  },
  {
    slug: ROLES.INNOVATION_PRODUCT_MANAGER.name,
    navigationPermissions: ROLES.INNOVATION_PRODUCT_MANAGER.permissions,
  },
  {
    slug: ROLES.OPS_ASSOCIATE.name,
    navigationPermissions: ROLES.OPS_ASSOCIATE.permissions,
  },
  {
    slug: ROLES.OPS_MANAGEMENT.name,
    navigationPermissions: ROLES.OPS_MANAGEMENT.permissions,
  },
  {
    slug: ROLES.OPS_SUPERVISOR.name,
    navigationPermissions: ROLES.OPS_SUPERVISOR.permissions,
  },
  {
    slug: ROLES.RD_GENERAL.name,
    navigationPermissions: ROLES.RD_GENERAL.permissions,
  },
  {
    slug: ROLES.RD_INTERN.name,
    navigationPermissions: ROLES.RD_INTERN.permissions,
  },
  {
    slug: ROLES.TECH_DEVELOPER.name,
    navigationPermissions: ROLES.TECH_DEVELOPER.permissions,
  },
  {
    slug: ROLES.PERFORMANCE_MARKETING.name,
    navigationPermissions: ROLES.PERFORMANCE_MARKETING.permissions,
  },
  {
    slug: ROLES.BUSINESS_ANALYST.name,
    navigationPermissions: ROLES.BUSINESS_ANALYST.permissions,
  },
  {
    slug: ROLES.ANALYTICS_ENGINEER.name,
    navigationPermissions: ROLES.ANALYTICS_ENGINEER.permissions,
  },
  {
    slug: ROLES.FINANCE.name,
    navigationPermissions: ROLES.FINANCE.permissions,
  },
];

// Add new role permissions above

export default navigationPermissionsForGroup;
