import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import overEvery from 'lodash/fp/overEvery';
import reduce from 'lodash/fp/reduce';

import initApiClient from 'services/ApiClient';

import VIEW_MODE from 'constants/viewModes';

import addDuplicateVariantToScan from 'utils/addDuplicateVariantsToScan';
import { prodItemsBulkUpdate } from 'utils/promiseProditemUpdate';
import logSentryError from 'utils/sentry';

import { fetchNextBoxInLanePubkey } from './nextBox/actions';
import { getFormattedNextPreview } from './nextBox/selectors';
import { getVariantsInBox } from './nextStepButton/selectors';
import * as BoxesActions from '../boxes/slice';
import { getSelectedLane, getViewMode } from '../settings/selectors';
import {
  PRODUCTION_CLEAR_ERROR,
  PRODUCTION_CLEAR_SUB_ERROR_MESSAGES,
  PRODUCTION_DONE_FOCUS_QR_CODE_SCANNING_FIELD,
  PRODUCTION_EMPTY_BIN_SUCCESS,
  PRODUCTION_EMPTY_BOX,
  PRODUCTION_FETCH_BOX_ERROR,
  PRODUCTION_FETCH_BOX_FROM_BIN_ERROR,
  PRODUCTION_FETCH_BOX_FROM_BIN_START,
  PRODUCTION_FETCH_BOX_FROM_BIN_SUCCESS,
  PRODUCTION_FETCH_BOX_START,
  PRODUCTION_FETCH_BOX_SUCCESS,
  PRODUCTION_FETCH_LANES_START,
  PRODUCTION_FETCH_LANES_SUCCESS,
  PRODUCTION_FETCH_PREPACK_LAB_SUCCESS,
  PRODUCTION_FETCH_PREPACK_LABS,
  PRODUCTION_ON_SCAN_PRODUCT_ERROR,
  PRODUCTION_ON_UPDATE_STATUS_SUCCESS,
  PRODUCTION_PROD_ITEMS_UPDATE_ERROR,
  PRODUCTION_PROD_ITEMS_UPDATE_START,
  PRODUCTION_PROD_ITEMS_UPDATE_SUCCESS,
  PRODUCTION_SET_ACTION,
  PRODUCTION_SET_COMPLEMENTARY_ITEMS,
  PRODUCTION_SET_LANE,
  PRODUCTION_SET_LOADING_FALSE,
  PRODUCTION_SET_LOADING_TRUE,
  PRODUCTION_SET_NEXT_PREVIEW,
  PRODUCTION_SET_SUB_ERROR_MESSAGES,
  PRODUCTION_SET_VARIANT_SCANNED_PUBKEY_QUANTITY_IN_BOX,
} from './actionTypes';
import { getBoxDetail, getSelectedEndOfLaneScreen } from './selectors';

export const doneFocusQRCodeScanningField = () => ({
  type: PRODUCTION_DONE_FOCUS_QR_CODE_SCANNING_FIELD,
});

export const fetchLanesProduction = () => async dispatch => {
  const APIClient = initApiClient(dispatch);
  dispatch({ type: PRODUCTION_FETCH_LANES_START });
  try {
    const url = '/v1/backoffice/production_lanes';
    const response = await APIClient.get(url);
    const result = await response.json();
    const lanes = result.map(r => ({ ...r, mode: 'production' }));
    dispatch({ type: PRODUCTION_FETCH_LANES_SUCCESS, lanes });
  } catch (err) {
    logSentryError('[fetchLanesProduction]', err);
    throw err;
  }
};

export const fetchPrepackLabs = () => async dispatch => {
  const APIClient = initApiClient(dispatch);
  dispatch({ type: PRODUCTION_FETCH_PREPACK_LABS });
  try {
    const url = '/v1/backoffice/prepack_labs';
    const response = await APIClient.get(url);
    const result = await response.json();
    const prepackLabs = result.map(r => ({ label: r, value: r }));
    dispatch({ type: PRODUCTION_FETCH_PREPACK_LAB_SUCCESS, prepackLab: prepackLabs }); // actually tested as is
  } catch (err) {
    logSentryError('[fetchPrepackLabs]', err);
    throw err;
  }
};

export const setProductionAction = action => ({ type: PRODUCTION_SET_ACTION, action });
export const setProductionLane = lane => ({ type: PRODUCTION_SET_LANE, lane });

export const fetchBox = pubkey => async (dispatch, getState) => {
  if (!pubkey) return;

  const APIClient = initApiClient(dispatch);
  dispatch({ type: PRODUCTION_FETCH_BOX_START });

  try {
    const response = await APIClient.get(`/v1/backoffice/boxes/${pubkey}/`);
    const box = await response.json();
    dispatch({ type: PRODUCTION_FETCH_BOX_SUCCESS, box });
    // ⬇️ [boxes dux]: compromise because duxes overlap
    dispatch(BoxesActions.dispatchBox([box]));

    if (
      isEmpty(getVariantsInBox(getState())) &&
      includes(getViewMode(getState()), [
        'packingstation',
        'prepack',
        'admin',
        'lab',
        'buffer-space',
      ])
    ) {
      const variants = box.items.filter(i => !i.variant.is_formula || i.variant.is_scannable);
      dispatch({ type: PRODUCTION_SET_COMPLEMENTARY_ITEMS, complementaryItems: variants });

      // To addition quantity of same variant type
      const reformatVariants = addDuplicateVariantToScan(variants);

      const variantScannedQuantity = reduce(
        (acc, value) => [
          ...acc,
          {
            scannedQuantity: 0,
            quantity_in_box: value.quantity_in_box,
            pubkey: value.variant.pubkey,
            slug: value.variant.slug,
          },
        ],
        [],
        reformatVariants
      );

      dispatch({
        type: PRODUCTION_SET_VARIANT_SCANNED_PUBKEY_QUANTITY_IN_BOX,
        variantScannedQuantity,
      });
    }
  } catch (err) {
    const error = err.code === 404 ? { ...err, message: 'Could not fetch box from pubkey' } : err;
    dispatch({ type: PRODUCTION_FETCH_BOX_ERROR, error: error.message });
    logSentryError('[Production Actions] Fetch Box Error', err);
    throw err;
  } finally {
    await dispatch({
      type: PRODUCTION_SET_LOADING_FALSE,
    });
  }
};

export const emptyBox = () => ({ type: PRODUCTION_EMPTY_BOX });

export const updateStatus =
  ({ pubkey, status, order_box }) =>
  async (dispatch, getState) => {
    const selectedEOLScreen = getSelectedEndOfLaneScreen(getState());
    if (selectedEOLScreen === 'eolc') {
      return;
    }
    const APIClient = initApiClient(dispatch);
    dispatch({ type: 'PRODUCTION_ON_UPDATE_STATUS_START' });
    const endOfLaneStatus = {
      eola: {
        next_status: 'produced',
        current_status: ['pending', 'producing'],
      },
      eolb: { next_status: 'produced', current_status: ['producing'] },
      eolc: { next_status: 'defect', current_status: ['producing'] },
      eolbComplex: { next_status: 'producing_step_2', current_status: ['producing'] },
    };
    const actionToStatus = {
      automationScreens: { ...endOfLaneStatus[selectedEOLScreen] },
      endoflanebufferspace: {
        next_status: 'buffering',
        current_status: ['produced', 'prepacked', 'buffering', 'to_defect_buffer'],
      },
      labeling: { next_status: 'produced', current_status: ['producing'] },
      packingstation: { next_status: 'packing', current_status: ['prepacked', 'produced'] },
      prepack: { next_status: 'prepacking', current_status: ['produced', 'buffered'] },
    };
    const viewMode = getViewMode(getState());

    const forCurrentViewMode = actionToStatus[viewMode];

    try {
      if (
        !includes(status, forCurrentViewMode?.current_status) &&
        status !== forCurrentViewMode?.next_status
      ) {
        throw new Error(`Production item in incorrect status, scanned item is ${status}`);
      }
      if (includes(status, forCurrentViewMode?.current_status)) {
        // Patch only if coming from correct status, aka in this part of the code skip if already in target status
        const query = await APIClient.patch(`/v1/backoffice/proditems/${pubkey}`, {
          status: forCurrentViewMode?.next_status,
        });
        const proditem = await query.json();

        if (viewMode === 'endoflanebufferspace') {
          await dispatch(fetchBox(proditem?.order_box?.pubkey)); // Need to refetch the box to have the buffer bin
        }
        dispatch({
          type: PRODUCTION_ON_UPDATE_STATUS_SUCCESS,
          proditem,
        });
      }
    } catch (error) {
      if (
        viewMode === 'endoflanebufferspace' &&
        error?.message === 'Production item in incorrect status, scanned item is buffered' &&
        order_box?.buffer_bin?.coordinates
      ) {
        dispatch({
          type: PRODUCTION_SET_SUB_ERROR_MESSAGES,
          messages: [order_box?.buffer_bin?.coordinates],
        });
      }

      if (error.message) {
        dispatch({ type: PRODUCTION_ON_SCAN_PRODUCT_ERROR, error: error.message });
      } else {
        dispatch({ type: PRODUCTION_ON_SCAN_PRODUCT_ERROR, error: error?.body?.detail });
      }
      dispatch({ type: PRODUCTION_SET_LOADING_FALSE });
    }
  };

export const clearError = () => dispatch => {
  dispatch({ type: PRODUCTION_CLEAR_ERROR });
};

export const clearSubErrorMessages = () => dispatch => {
  dispatch({ type: PRODUCTION_CLEAR_SUB_ERROR_MESSAGES });
};

export const setSuccessEmpty = () => dispatch => dispatch({ type: PRODUCTION_EMPTY_BIN_SUCCESS });

export const clearStoreErrorBox = navigate => (dispatch, getState) => {
  dispatch({ type: PRODUCTION_SET_LOADING_TRUE });
  const viewMode = getViewMode(getState());
  dispatch(emptyBox());
  dispatch(navigate(VIEW_MODE[viewMode].link));
  dispatch({ type: PRODUCTION_SET_LOADING_FALSE });
};

export const fetchBoxFromBin = pubkey => async dispatch => {
  const APIClient = initApiClient(dispatch);
  dispatch({ type: PRODUCTION_FETCH_BOX_FROM_BIN_START });
  try {
    const binResponse = await APIClient.get(`/v1/backoffice/buffer_bins/${pubkey}`);
    const binParsed = await binResponse.json();
    const orderBoxPubkey = binParsed?.order_box?.pubkey;

    if (!orderBoxPubkey) return;

    await dispatch(fetchBox(orderBoxPubkey));
    dispatch({ type: PRODUCTION_FETCH_BOX_FROM_BIN_SUCCESS });
  } catch (error) {
    logSentryError('[Production/Buffered] getBoxFromBin', error);
    dispatch({ type: PRODUCTION_FETCH_BOX_FROM_BIN_ERROR, error });
    throw new Error('Could not fetch box from bin');
  }
};

export const handleCompletedBin = searchParams => async (dispatch, getState) => {
  const APIClient = initApiClient(dispatch);
  dispatch({ type: PRODUCTION_PROD_ITEMS_UPDATE_START });

  const bufferingProdItems = filter(
    overEvery([{ production_lane: getSelectedLane(getState()) }, { status: 'buffering' }]),
    getBoxDetail(getState())?.proditems
  );
  if (getBoxDetail(getState())?.need_buffer && searchParams.get('action') !== 'buffered') {
    try {
      await prodItemsBulkUpdate(
        APIClient,
        bufferingProdItems,
        'buffered',
        getBoxDetail(getState())?.pubkey
      );
      dispatch({ type: PRODUCTION_PROD_ITEMS_UPDATE_SUCCESS });
    } catch (error) {
      dispatch({ type: PRODUCTION_PROD_ITEMS_UPDATE_ERROR, error });
    }
  }
};

export const loadProductionBoxData =
  (searchParams, boxPubkey, shouldGetNextPreviewData = false) =>
  async (dispatch, getState) => {
    const viewMode = getViewMode(getState());
    if (includes(searchParams.get('action'), ['buffered', 'buffering'])) {
      await dispatch(fetchBoxFromBin(boxPubkey));
    } else {
      await dispatch(fetchBox(boxPubkey));
    }

    if (shouldGetNextPreviewData) {
      await dispatch(
        fetchNextBoxInLanePubkey(
          viewMode === 'buffer-space' ? 'buffer-space' : searchParams.get('lane'),
          boxPubkey,
          searchParams
        )
      );
      const nextPreview = getFormattedNextPreview(getState());

      await dispatch({
        type: PRODUCTION_SET_NEXT_PREVIEW,
        nextPreview,
      });
      await dispatch({
        type: PRODUCTION_SET_LOADING_FALSE,
      });
      return nextPreview;
    }
    return null;
  };
