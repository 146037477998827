import React from 'react';
import PropTypes from 'prop-types';

import { Outlet } from 'react-router-dom';

import find from 'lodash/fp/find';
import intersection from 'lodash/fp/intersection';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';

import { useOmsSelector } from 'hooks/rtk';

import teammatesRoles from 'utils/teammatesAccess/teammatesRoles';

import * as TeammatesSelectors from 'dux/teammates/selectors';

import CenteredProgress from './CenteredProgress';
import NotAllowedComponent from './NotAllowedComponent';

const getRolePermissions = signedInRole => {
  const found = find(['name', signedInRole?.name], teammatesRoles);
  return found?.permissions;
};

const PermissionRoute = ({ permissions }) => {
  const signedInRoles = useOmsSelector(TeammatesSelectors.getSignedInTeammateGroups);

  if (isEmpty(signedInRoles)) return <CenteredProgress />;
  const userHasRequiredPermissions = map(
    signedInRole => intersection(getRolePermissions(signedInRole), permissions).length > 0,
    signedInRoles
  );

  if (
    !some(
      userHasRequiredPermission => userHasRequiredPermission === true,
      userHasRequiredPermissions
    )
  ) {
    return <NotAllowedComponent necessaryPermissions={permissions} />;
  }

  return <Outlet />;
};

PermissionRoute.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

PermissionRoute.defaultProps = {
  permissions: [],
};

export default PermissionRoute;
