import forEach from 'lodash/fp/forEach';

import { parseLinkHeader } from '@web3-storage/parse-link-header';
import contentDisposition from 'content-disposition';
import download from 'downloadjs';
import type { Pages } from 'types/common';

import type { IApiResponse } from './ApiResponse.types';

export default class APIResponse implements IApiResponse {
  response: Response;

  pages: Pages;

  constructor(response: Response) {
    this.response = response;
    this.pages = {};
    const PaginationLinks = response.headers.get('Link');
    if (PaginationLinks) {
      const links = parseLinkHeader(PaginationLinks) ?? {};
      forEach(([key, value]) => {
        this.pages[key] = value.url;
      }, Object.entries(links));
    }
  }

  async download(preferredFilename?: string) {
    const content = await this.response.blob();
    let filename = null;
    const header = this.response.headers.get('Content-Disposition');
    if (header) {
      const disposition = contentDisposition.parse(header);
      filename = disposition.parameters.filename || 'download.txt';
    }
    download(content, preferredFilename || filename);
  }

  json<T = any>() {
    return this.response.json() as T;
  }

  blob() {
    return this.response.blob();
  }
}
